<template>
  <v-stepper-content step="12"
                     class="pa-1">

    <div v-html="$t('add-rule-step-set-point-definition.info-text')"/>

    <!-- other data types -->
    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  icon="tune"
                  class="my-5">
      <template v-slot:content>
        <double-switch-input
            v-model="state"
            :actuator="item.actuator"
            :device="item.device"
            :updateOnChange="false"
            @change="updateSetPoint" />
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations from "@/scripts/automations";
import DoubleSwitchInput from "@/templates/components/devices/actuators/DoubleSwitchInput.vue";

export default {
  name: 'AddRuleStepDoubleSmartModuleActionDefinition',
  components: {
    DoubleSwitchInput,
    ContentCard
  },

  props: {
    item: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      state: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      if (this.state !== null
          && this.state >= Number(this.item?.actuator?.minValue)
          && this.state <= Number(this.item?.actuator?.maxValue)
      ) {
        this.updateModel() // refresh v-model to current value
        return true
      }
      return false
    }
  },

  methods: {
    setSetPoint(value) {
      this.state = value
      this.updateSetPoint()
    },

    updateSetPoint() {
      this.switchOperation = null
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        deviceId: this.item?.device?.id,
        state: this.state,
        type: automations.actionTypes.sgready // JSON:API Resource type
      })
    }
  }
};
</script>
