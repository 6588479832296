<template>
  <v-radio-group v-model="value"
                 class="non-flip px-2"
                 @change="change"
                  dense>
    <v-radio :label="$t('double-smart-module.state.1')" value="1"/>
    <v-radio :label="$t('double-smart-module.state.2')" value="2"/>
    <v-radio :label="$t('double-smart-module.state.3')" value="3"/>
    <v-radio :label="$t('double-smart-module.state.4')" value="4"/>
  </v-radio-group>
</template>

<script>
export default {
  name: 'DoubleSwitchInput',

  props: {
    device: Object,
    actuator: Object,
    dense: Boolean,
    updateOnChange: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      value: this.device.value,
      allowValueChange: true,
      cooldown: 3000,
      changeTimeout: null,
      loading: false
    }
  },

  methods: {
    change() {
      if (this.updateOnChange) {
        this.updateProp()
      }

      this.$emit('input', this.value)
    },

    updateProp() {
      if (!this.updateOnChange) return;

      this.loading = true
      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        this.allowValueChange = true
      }, this.cooldown)
      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.device.id,
          value: this.value
        }
      }, () => {
        this.loading = false
      }, (error) => {
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        console.error(error)
        this.loading = false
      })
    }
  },
  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.value = this.device?.value
      }
    }
  }
}
</script>
