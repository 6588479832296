<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>

      <v-progress-linear
        :value="progress"
        color="primary accent-4"
        height="5"
        :buffer-value="bufferValue"
        stream
      ></v-progress-linear>

      <v-stepper v-model="stepper"
                 flat class="transparent">

        <v-stepper-items ref="stepper">
          <v-stepper-content step="start"
                             class="pa-0 pt-4">
            <add-device-step-start></add-device-step-start>
          </v-stepper-content>

          <v-stepper-content step="adding-mode-requested"
                             class="pa-0 pt-4">
            <add-device-step-adding-mode-requested></add-device-step-adding-mode-requested>
          </v-stepper-content>

          <v-stepper-content step="adding"
                             class="pa-0 pt-4">
            <add-device-step-adding></add-device-step-adding>
          </v-stepper-content>

          <v-stepper-content step="process-control"
                             class="pa-0 pt-4">
            <add-device-step-process-control></add-device-step-process-control>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0 pt-4">
            <add-device-step-success :device.sync="newDevice" @updatedAlert="updateDeviceAlert"></add-device-step-success>

            <v-checkbox
              v-if="$isFavourable(newDevice?.type)"
              class="ma-3"
              v-model="addToFav"
              :label="$t('add-device-dialog.add-favorite')"
              color="primary"
              hide-details
            ></v-checkbox>

          </v-stepper-content>

          <v-stepper-content step="error"
                             class="pa-0 pt-4">
            <add-device-step-error></add-device-step-error>
          </v-stepper-content>

          <v-stepper-content step="gateway-error"
                             class="pa-0 pt-4">
            <add-device-step-gateway-error></add-device-step-gateway-error>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>

    <template v-slot:actions>
      <v-stepper v-model="stepper"
                 width="100%"
                 flat class="transparent">
        <v-stepper-items>
          <v-stepper-content step="start"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   :loading="loading"
                   :disabled="loading"
                   class="font-weight-bold action-button"
                   v-text="$t('add-device-dialog.start-adding')"
                   @click="startAdding">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="success"
                             class="pa-0">
            <v-btn depressed large
                   color="primary"
                   class="font-weight-bold action-button"
                   v-text="$t('app.close')"
                   @click="close">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="error"
                             class="pa-0">
            <v-btn depressed large
                   class="font-weight-bold action-button"
                   v-text="$t('app.close')"
                   @click="close">
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="gateway-error"
                             class="pa-0">
            <v-btn depressed large
                   class="font-weight-bold action-button"
                   @click="close"
                   v-text="$t('app.close')"/>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import AddDeviceStepStart from "@/templates/dialogs/addDevice/AddDeviceStepStart";
import AddDeviceStepAdding from "@/templates/dialogs/addDevice/AddDeviceStepAdding";
import AddDeviceStepProcessControl from "@/templates/dialogs/addDevice/AddDeviceStepProcessControl";
import AddDeviceStepError from "@/templates/dialogs/addDevice/AddDeviceStepError";
import AddDeviceStepGatewayError from "@/templates/dialogs/addDevice/AddDeviceStepGatewayError";
import AddDeviceStepSuccess from "@/templates/dialogs/addDevice/AddDeviceStepSuccess";
import AddDeviceStepAddingModeRequested from "@/templates/dialogs/addDevice/AddDeviceStepAddingModeRequested";

export default {
  name: 'AddDeviceDialog',
  components: {
    AddDeviceStepAddingModeRequested,
    AddDeviceStepError,
    AddDeviceStepStart,
    AddDeviceStepAdding,
    AddDeviceStepProcessControl,
    FullscreenOverlayFrame,
    AddDeviceStepSuccess,
    AddDeviceStepGatewayError
  },
  props: ['data'],
  data: function () {
    return {
      loading: false,
      intervals: {
        init: {
          handle: null,
          time: 2000,
          maxIntervals: 3,
          count: 0
        },
        controllerMode: {
          handle: null,
          time: 5000,
          maxIntervals: 7,
          count: 0
        },
        newDeviceCheck: {
          handle: null,
          time: 5000,
          maxIntervals: 20,
          count: 0
        }
      },
      timeouts: {
        controllerMode: {
          handle: null,
          time: 30000
        },
        addingMode: {
          handle: null,
          time: 60000
        }
      },
      title: this.$t('add-device-dialog.title'),
      color: 'primary',
      stepper: 'start',
      progress: 0,
      bufferValue: 100,
      controllerModes: Object.freeze({
        NORMAL: 'NORMAL',
        ADDING: 'ADDING',
        PROCESS_CONTROL_EVENT: 'PROCESS_CONTROL_EVENT'
      }),
      deviceList: null,
      waitingForDevice: false,
      newDevice: null,
      addToFav: true
    }
  },
  methods: {
    /**
     * init
     */
    init() {
      this.stepper = 'start'
      this.loading = true
      this.progress = 0
      this.bufferValue = 100
      this.waitingForDevice = false
      this.newDevice = null
      this.addToFav = true

      this.intervals.init.count = 0
      this.intervals.controllerMode.count = 0
      this.intervals.newDeviceCheck.count = 0

      this.getDevices()
          .then((devices) => {
            this.deviceList = devices
          })

      this.intervals.init.handle = setInterval(() => {
        this.intervals.init.count++
        this.getControllerMode()
            .then((mode) => {
              this.loading = mode !== this.controllerModes.NORMAL
              if (this.loading === false) {
                clearInterval(this.intervals.init.handle)
                this.intervals.init.handle = undefined
              } else if (this.intervals.init.count >= this.intervals.init.maxIntervals) {
                // show error after a few retries - the gateway is not in normal mode
                clearInterval(this.intervals.init.handle)
                this.intervals.init.handle = undefined
                this.showGatewayError()
              }
            })
            .catch(() => {
              if (this.intervals.init.count >= this.intervals.init.maxIntervals) {
                clearInterval(this.intervals.init.handle)
                this.intervals.init.handle = undefined
                this.showGatewayError()
              }
            })
      }, this.intervals.init.time)
    },

    /**
     * start adding mode
     */
    startAdding() {
      this.getControllerMode()
          .then((mode) => {
            if (mode === this.controllerModes.NORMAL) {
              this.setControllerMode(this.controllerModes.ADDING)
                  .then(() => {
                    this.progress = 25
                    this.bufferValue = 0
                    this.stepper = 'adding-mode-requested'
                    this.intervals.newDeviceCheck.count = 0
                    this.startControllerModeWatching()
                  })
                  .catch(() => {
                    this.showGatewayError()
                  })

            } else {
              this.showGatewayError()
            }
          })
          .catch(() => {
            this.showError()
          })
    },

    /**
     * strat controller mode watching
     */
    startControllerModeWatching() {
      // if controller does not enter adding mode
      this.timeouts.controllerMode.handle = setTimeout(
        () => {
          this.showGatewayError()
        },
        this.timeouts.controllerMode.time
      )

      this.intervals.controllerMode.handle = setInterval(() => {

        if(this.intervals.controllerMode.count >= this.intervals.controllerMode.maxIntervals) {
          clearInterval(this.intervals.controllerMode.handle)
          this.showStep('error', 100)
          return
        }

        this.getControllerMode()
            .then((mode) => {

              if (mode === this.controllerModes.ADDING) {
                this.intervals.controllerMode.count++
                this.showStep('adding', 50)
                this.waitingForDevice = true
                clearTimeout(this.timeouts.controllerMode.handle)
                this.timeouts.controllerMode.handle = undefined
                // if no device gets found, show error page after 60 seconds
                if(!this.timeouts.addingMode.handle) {
                  this.timeouts.addingMode.handle = setTimeout(
                    () => {
                      this.showGatewayError()
                      clearTimeout(this.timeouts.addingMode.handle)
                    },
                    this.timeouts.addingMode.time
                  )
                }
              }

              if (mode === this.controllerModes.NORMAL) {
                this.intervals.newDeviceCheck.handle = setInterval(() => {
                  if (this.waitingForDevice) {
                    // stop watching controller mode - we only need to wait for the new device now
                    this.stopWatching()
                    // increment device check counter
                    this.intervals.newDeviceCheck.count++
                    // check for a new device
                    this.checkNewDeviceAdded().then((newDevice) => {
                      if (newDevice) {
                        this.newDevice = newDevice
                        this.waitingForDevice = false
                        this.loading = false
                        // clear error state timeout
                        clearTimeout(this.timeouts.controllerMode.handle)
                        this.showStep('success', 100)
                        clearInterval(this.intervals.newDeviceCheck.handle)
                      } else {
                        if (this.intervals.newDeviceCheck.count >= this.intervals.newDeviceCheck.maxIntervals) {
                          this.waitingForDevice = false
                          // clear error state timeout
                          clearTimeout(this.timeouts.controllerMode.handle)
                          this.showError()
                        }
                      }
                    })
                  }
                }, this.intervals.newDeviceCheck.time)
              }

              if (mode === this.controllerModes.PROCESS_CONTROL_EVENT) {
                this.waitingForDevice = true
                if(this.timeouts.addingMode.handle) {
                  clearTimeout(this.timeouts.addingMode.handle)
                  this.timeouts.addingMode.handle = undefined
                }
                this.showStep('process-control', 75)
                // clear error state timeout
                clearTimeout(this.timeouts.controllerMode.handle)
              }

            })
            .catch(() => {
              if (this.intervals.controllerMode.count >= this.intervals.controllerMode.maxIntervals) {
                this.showGatewayError()
              }
            })
      }, this.intervals.controllerMode.time)
    },

    /**
     * check if new device added
     * @returns {Promise<unknown>}
     */
    checkNewDeviceAdded() {
      return new Promise((resolve, reject) => {
        this.getDevices()
            .then((response) => {
              let difference = Object.keys(response).filter(x => !Object.keys(this.deviceList).includes(x))
              difference.forEach((deviceId) => {
                let newDevice = response[deviceId]
                resolve(newDevice)
              })
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    /**
     * get the controller mode
     * @returns {Promise<unknown>}
     */
    getControllerMode() {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendGet(
          {
            endpoint: 'gateway/get-mode'
          },
          (response) => {
            if (response.data.data?.data?.mode) {
              resolve(response.data.data.data.mode)
            } else {
              reject(new Error('invalid response body'))
            }
          },
          (err) => {
            reject(err)
          }
        )
      })
    },

    /**
     * set the controller mode
     * @param controllerMode
     * @returns {Promise<unknown>}
     */
    setControllerMode(controllerMode) {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendPost(
          {
            endpoint: 'gateway/set-mode?mode=' + controllerMode,
            timeout: 10000,
            data: {}
          },
          (response) => {
            if (response.data?.data) {
              resolve(response.data.data)
            } else {
              reject(new Error('invalid response body'))
            }
          },
          (err) => {
            reject(err)
          }
        )
      })
    },

    /**
     * get devices from gateway
     * @returns {Promise<unknown>}
     */
    getDevices() {
      return new Promise((resolve, reject) => {
        this.$rhRequest.sendGet(
          {
            endpoint: 'devices/get',
          },
          (response) => {
            if (response.data?.data) {
              resolve(response.data.data)
            } else {
              reject(new Error('invalid response body'))
            }
          },
          (err) => {
            reject(err)
          }
        )
      })
    },

    /**
     * wrapper to set current step and progress
     * @param step
     * @param progress
     */
    showStep(step, progress) {
      this.progress = progress
      this.stepper = step
    },

    /**
     * show device adding error
     */
    showError() {
      this.loading = false
      this.stopWatching()
      clearInterval(this.intervals.newDeviceCheck.handle)
      this.intervals.newDeviceCheck.handle = undefined
      this.showStep('error', 100)
    },

    /**
     * show gateway error
     */
    showGatewayError() {
      this.loading = false
      this.stopWatching()
      clearInterval(this.intervals.newDeviceCheck.handle)
      this.intervals.newDeviceCheck.handle = undefined
      this.showStep('gateway-error', 100)
    },

    /**
     * clear controller mode watching interval
     */
    stopWatching() {
      clearInterval(this.intervals.controllerMode.handle)
      this.intervals.controllerMode.handle = undefined
    },

    /**
     * clear interval handles
     */
    clearIntervals() {
      Object.keys(this.intervals).forEach((k) => {
        clearInterval(this.intervals[k].handle)
        this.intervals[k].handle = undefined
      })
    },

    /**
     * clear one interval handle by given key
     * @param key
     */
    clearInterval(key) {
      clearInterval(this.timeouts[key].handle)
      this.timeouts[key].handle = undefined
    },

    /**
     * clear timeout handles
     */
    clearTimeouts() {
      Object.keys(this.timeouts).forEach((k) => {
        clearTimeout(this.timeouts[k].handle)
        this.timeouts[k].handle = undefined
      })
    },

    addDeviceToFavorites() {
      if (this.newDevice === null) return
      if (!this.$isFavourable(this.newDevice?.type)) return;
      if (this.addToFav !== true) return

      this.$rhRequest.sendGet({
        endpoint: 'devices/add-device-to-favorites',
        params: {
          deviceId: '' + this.newDevice.id
        }
      }, () => {
        this.$root.bisatoast.success({message: this.$t('device-dialog.add-favorites.success'), showCloseBtn: true})
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
      })
    },

    /**
     * abort adding process
     */
    abort() {
      // set gateway mode back to normal when the user aborts the adding process
      this.getControllerMode().then((mode) => {
        if (mode !== this.controllerModes.NORMAL) {
          this.setControllerMode(this.controllerModes.NORMAL)
        }
      })

      this.close()
    },

    /**
     * close dialog
     */
    close() {
      this.clearIntervals()
      this.clearTimeouts()

      if (this.stepper === "success") {
        this.addDeviceToFavorites()
      }
      this.$root.$emit('updateDeviceList')
      this.$root.bisadialog.toggle('addDevice')
    },
    updateDeviceAlert(alertName) {
      this.newDevice[alertName] = !this.newDevice[alertName]
    }
  }
};
</script>

